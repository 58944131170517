@charset "UTF-8";

// Color
@import "components/color-variables";
@import "components/color-classes";

// Variables;
@import "components/variables";

// Reset
@import "components/normalize";

// components
@import "components/global";
@import "components/badges";
@import "components/icons-material-design";
@import "components/grid";
@import "components/navbar";
@import "components/typography";
@import "components/transitions";
@import "components/cards";
@import "components/toast";
@import "components/tabs";
@import "components/tooltip";
@import "components/buttons";
@import "components/dropdown";
@import "components/waves";
@import "components/modal";
@import "components/collapsible";
@import "components/chips";
@import "components/materialbox";
@import "components/forms/forms";
@import "components/table_of_contents";
@import "components/sidenav";
@import "components/preloader";
@import "components/slider";
@import "components/carousel";
@import "components/tapTarget";
@import "components/pulse";
@import "components/datepicker";
@import "components/timepicker";

@import 'ember-power-select';


.img-h:hover{
    -moz-transform: scale(1.1);
-webkit-transform: scale(1.1);
transform: scale(1.1);
max-width: 100%;
-moz-transition: all 0.3s;
-webkit-transition: all 0.3s;
transition: all 0.3s;
}

.table-head{
    background: #717B8B;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.m-action{
    margin: 5px;
}
.t-justify{
    text-align: justify;
}